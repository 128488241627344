<script>

export default {
  methods: {
    submitEvent( event ) {
      const fields = Object.fromEntries(new FormData(event.target));
      console.log(fields);
    }
  }
}
</script>

<template>
  <div>
    <h3>Información del software de nomina</h3>
    <form @submit.prevent="submitEvent">
      <div class="wrapper-fields">
        <div class="wrapper-input" title="Identificación nomina">
          <label for="id">ID</label>
          <b-form-input id="id" name="idpayroll" required></b-form-input>
        </div>

        <div class="wrapper-input" title="Pin nomina">
          <label for="pin">Pin</label>
          <b-form-input id="pin" name="pinpayroll" required></b-form-input>
        </div>
      </div>
      <vs-button  >Guardar</vs-button>
    </form>
  </div>
</template>

<style scoped>
.wrapper-input {
  display: flex;
  flex-direction: column;
  margin-bottom: .5rem;
}

.wrapper-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
</style>